import * as actions from "./constants";

export const loginFB = (call) => ({
  type: actions.FB_AUTH_LOGIN_REQUEST,
  call: call
});

export const signUp = user => ({
  type: actions.EMAIL_AUTH_REGISTER_REQUEST,
  user
});

export const login = (user, callback = false) => ({
  type: actions.EMAIL_AUTH_LOGIN_REQUEST,
  user,
  callback
});

export const reset = (data) => ({
  type: actions.EMAIL_AUTH_RESET,
  data,
});

export const logout = _ => ({
  type: actions.EMAIL_AUTH_LOGOUT
});

export const resetPassword = email => ({
  type: actions.EMAIL_AUTH_PASSWORD_RECOVER_REQUEST,
  email
});

export const updateUser = user => ({
  type: actions.USER_UPDATE_REQUEST,
  user
});

export const setPassword = (password, token) => ({
  type: actions.EMAIL_AUTH_PASSWORD_RESET_REQUEST,
  password,
  token
});