import React from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
const RouteGuard = ({
  isProtected = false,
  accessToken,
  render: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isProtected) {
          return accessToken ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        } else {
          return accessToken ? (
            <Redirect
              to={{
                pathname: "/dashboard"
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      }}
    />
  )
}
const mapStateToProps = state => ({
  accessToken: state.Login.accessToken
})
const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(RouteGuard)
