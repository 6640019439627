import { all, call, put, takeLatest } from "redux-saga/effects"
import {addjournalsError} from "./actions"

// redux
import types from "./types"
import {appConfig} from '../../../config/app'

//Utils
import XHR from "../../../utils/xhr"

import { getEntrieSuccess,addjournalSuccess, reset} from "../redux/actions"

function AddJournals(data) {
  const URL = `${appConfig.apiUrl}/api/v1/journal/`
  const token = localStorage.getItem("token")
  console.log("token", token)
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}
function* AddjournalsData({ data}) {
  try {
    const response = yield call(AddJournals, data)
    yield put(addjournalSuccess(response.data.title))
    
  } catch (e) {
    const { response } = e
    yield put(addjournalsError(response.data))
  }
  finally{
    yield put(reset())
  }
}

function getJournels() {
  const URL = `${appConfig.apiUrl}/api/v1/journal/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token
    },
    method: "GET"
  }
  return XHR(URL, options)
}
function* getJournalsData() {
  try {
    const response = yield call(getJournels)
    yield put(getEntrieSuccess(response.data.results))
  } catch (e) {
    // const { response } = e
  }
}

export default all([
  takeLatest(types.addjournals, AddjournalsData),
  takeLatest(types.getEntries, getJournalsData)
])
