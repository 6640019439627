import { all, takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { DRIVER_GET_REQUEST, DRIVER_GET_REQUEST_SUCCESS, DRIVER_ADD_REQUEST, DRIVER_REQUEST_ERROR, DRIVER_REQUEST_SUCCESS, DRIVER_EDIT_REQUEST, DRIVER_EDIT_REQUEST_SUCCESS, DRIVER_EDIT_REQUEST_ERROR, DRIVER_SUSPEND_REQUEST, ACTIVATE_SUSPEND_REQUEST } from './constants';
import { request, setupHttpConfig } from '../../../utils/http';

function getRequest() {
  const res = request.get('/api/v1/driverprofile/');
  //There is no reset password endpoint in backend, it's just a fake url
  return res;
}

function postDriverUser({ data }) {
  var bodyFormData = new FormData();
  bodyFormData.append("email", data.email);
  bodyFormData.append("first_name", data.first_name);
  bodyFormData.append("last_name", data.last_name);
  bodyFormData.append("password", data.password);
  return request.post('/api/v1/user-update/', bodyFormData);
}

function suspendActiveDriver({ data }) {
  var bodyFormData = new FormData();
  bodyFormData.append("is_active", data.is_active);
  bodyFormData.append("email", data.email);
  return request.post('/api/v1/user-update/', bodyFormData);
}

function updateDriverInfo({ data }) {
  var bodyFormData = new FormData();
  bodyFormData.append("phone", data.phone);
  bodyFormData.append("address", data.address);
  if(data.photo){
    bodyFormData.append("photo", data.photo);
  }
  bodyFormData.append("driver_licence", data.driver_licence);
  bodyFormData.append("user", data.user);
  bodyFormData.append("vehicle_model", data.vehicle_model);
  bodyFormData.append("bank_name", data.bank_name);
  bodyFormData.append("account_number", data.account_number);
  bodyFormData.append("date_of_birth", data.date_of_birth);
  request.defaults.headers.common["Content-Type"] = "multipart/form-data";
  request.defaults.timeout = 50000;
  return request.patch('/api/v1/driverprofile/'+data.id+"/", bodyFormData);
}

function postDriver({ data }) {

  var bodyFormData = new FormData();
  bodyFormData.append("first_name", data.first_name);
  bodyFormData.append("last_name", data.last_name);
  bodyFormData.append("phone", data.phone);
  bodyFormData.append("email", data.email);
  bodyFormData.append("address", data.address);
  bodyFormData.append("photo", data.photo);
  bodyFormData.append("driver_licence", data.driver_licence);
  bodyFormData.append("vehicle_model", data.vehicle_model);
  bodyFormData.append("bank_name", data.bank_name);
  bodyFormData.append("account_number", data.account_number);
  bodyFormData.append("date_of_birth", data.date_of_birth);
  bodyFormData.append("date_of_birth", data.date_of_birth);
  request.defaults.headers.common["Content-Type"] = "multipart/form-data";
  request.defaults.timeout = 50000;
  return request.post('/api/v1/driver-add/', bodyFormData);
}

function* getDrivers(action) {
  setupHttpConfig();
  try {
    const { status, data } = yield call(getRequest);
    if (status === 200) {
      yield put({ type: DRIVER_GET_REQUEST_SUCCESS, data: data });
    }
  } catch (error) {
  }
}


function* addDriver(action) {
  try {
    const { status, data } = yield call(postDriver, { data: action.data });
    if (status === 200) {
      toast.success("New Chef Added Successfully..");
      yield put({ type: DRIVER_REQUEST_SUCCESS, data: data.success });
    } else {
      yield put({ type: DRIVER_REQUEST_ERROR, error: "Error in submitting review" });
      toast.error("Error in saving driver, Fields are missing or Chef with email already exist..")
    }
  } catch (error) {
    toast.error("Error in saving driver, Fields are missing or Chef with email already exist..")
    yield put({ type: DRIVER_REQUEST_ERROR, error: "Error in saving driver, Fields are missing or Chef with email already exist.." });
  }
}


function* editDriver(action) {
  try {
    const { status, data } = yield call(postDriverUser, { data: action.data });
    yield call(updateDriverInfo, { data: action.data });
    if (status === 200) {
      toast.success("Chef Updated Successfully..");
      yield put({ type: DRIVER_EDIT_REQUEST_SUCCESS, data: data.success });
      yield put({ type: DRIVER_GET_REQUEST, data: data });
    } else {
      yield put({ type: DRIVER_EDIT_REQUEST_ERROR, error: "Error in submitting review" });
      toast.error("Error in saving driver, Fields are missing or Chef with email already exist..")
    }
  } catch (error) {
    toast.error("Error in saving driver, Fields are missing or Chef with email already exist..")
    yield put({ type: DRIVER_REQUEST_ERROR, error: "Error in saving driver, Fields are missing or Chef with email already exist.." });
  }
}

function* suspendAcitivateDriver(action) {
  try {
    const { status, data } = yield call(suspendActiveDriver, { data: action.data });
    if (status === 200) {
      toast.success("Chef Updated Successfully..");
      yield put({ type: DRIVER_GET_REQUEST, data: data });
    } else {
      yield put({ type: DRIVER_EDIT_REQUEST_ERROR, error: "Error in submitting review" });
      toast.error("Error in saving driver..")
    }
  } catch (error) {
    toast.error("Error in saving driver..")
    yield put({ type: DRIVER_REQUEST_ERROR, error: "Error in saving driver.." });
  }
}

export default all([
  takeLatest(DRIVER_GET_REQUEST, getDrivers),
  takeLatest(DRIVER_ADD_REQUEST, addDriver),
  takeLatest(DRIVER_EDIT_REQUEST, editDriver),
  takeLatest(DRIVER_SUSPEND_REQUEST, suspendAcitivateDriver),
  takeLatest(ACTIVATE_SUSPEND_REQUEST, suspendAcitivateDriver),
]);
