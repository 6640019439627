import types from "./types"

const initialState = {
  journalData: [],
  addjournalsError: false,
  addjournalSuccess: "",
  requesting: false,
}

export default (state = initialState, action) => {
  
  switch (action.type) {

    case types.addjournals:
      return {
        ...state,
        requesting: true,
      }
    case types.getEntrieSuccess:
      return {
        ...state,
        requesting: false,
        journalData: action.data
      }
      case types.addjournalsError:
      return {
        ...state,
        addjournalsError: action.data
      }
      case types.reset:
      return {
        ...state,
        requesting: false,
      }
      case types.addjournalSuccess:
      return {
        ...state,
        addjournalSuccess: action.data
      }
      

    default:
      return state
  }
}
