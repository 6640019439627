import { all, takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  SET_AUDIO_DATA,
  SET_AUDIO_DATA_ERROR
} from './constants';
// import { request } from '../../../utils/http';
// import { AxiosResponse } from 'axios';
// import { User, Place, Profile } from '../../../types';


function getDataAPI(action) {
  console.log('getDataAPI action', action);

  // return request.post('/api/v1/login/', {
  //   action
  // });
}


function* setAudioData(action) {
  console.log("setAudioData:: ", action);
  try {
    yield call(getDataAPI, action);

  } catch (error) {
    console.log(error);
    toast.error("Can't sign in with provided credentials..")
    // todo add errors with similar structure in backend
    yield put({ type: SET_AUDIO_DATA_ERROR, error: "Can't sign in with provided credentials" });
  }
}



export default all([
  takeLatest(SET_AUDIO_DATA, setAudioData),
]);
