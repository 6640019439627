import React, { Component } from "react"
import { HashRouter, Route, Switch } from "react-router-dom"

import "./App.scss"
import { setupHttpConfig } from "./utils/http"
import RouteGuard from "../src/RouterGuard/index"

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)
// Containers
// const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"))
// const Login = React.lazy(() => import('./containers/Login'));
const Dashboard = React.lazy(() => import("./features/Dashboard"))
const Dashboard2 = React.lazy(() => import("./features/Dashboard2"))
const SafeCheck = React.lazy(() => import("./features/SafeCheck"))
const Register = React.lazy(() => import("./features/Register"))
const Login = React.lazy(() => import("./features/Login"))
const MyFiles = React.lazy(() => import("./features/MyFiles"))
const MyFiles2 = React.lazy(() => import("./features/MyFiles2"))
const Journal = React.lazy(() => import("./features/Journal"))
const WriteJournal = React.lazy(() => import("./features/WriteJournal"))
const LearnPage = React.lazy(() => import("./features/Learn"))

//@InertModuleDeclaration

class App extends Component {
  async componentWillMount() {
    setupHttpConfig()
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route path="/main" name="Home" render={props => <DefaultLayout {...props}/>} /> */}
            {/* <Route path="/" name="Login" render={props => <Login {...props} />} /> */}
            <Route
              exact
              path="/"
              name="Login"
              render={props => <Login {...props} />}
            />
            <Route
              path="/register"
              name="Register"
              render={props => <Register {...props} />}
            />
            <RouteGuard
              path="/dashboard"
              name="Dashboard"
              render={props => <Dashboard {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/dashboard2"
              name="Dashboard2"
              render={props => <Dashboard2 {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/safecheck"
              name="SafeCheck"
              render={props => <SafeCheck {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/myfiles"
              name="MyFiles"
              render={props => <MyFiles {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/myfiles2"
              name="MyFiles2"
              render={props => <MyFiles2 {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/journal"
              name="Journal"
              render={props => <Journal {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/writejournal"
              name="WriteJournal"
              render={props => <WriteJournal {...props} />}
              isProtected={true}
            />
            <RouteGuard
              path="/learnpage"
              name="LearnPage"
              render={props => <LearnPage {...props} />}
              isProtected={true}
            />
            {/*InsertModuleRouting*/}
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
