export const EMAIL_AUTH_NEW_LOGIN_REQUEST = "EMAIL_AUTH_NEW_LOGIN_REQUEST"
export const EMAIL_AUTH_NEW_LOGIN_SUCCESS = "EMAIL_AUTH_NEW_LOGIN_SUCCESS"
export const EMAIL_AUTH_NEW_LOGIN_ERROR = "EMAIL_AUTH_NEW_LOGIN_ERROR"

export const FB_AUTH_LOGIN_REQUEST = "FB_AUTH_LOGIN_REQUEST"
export const FB_AUTH_LOGIN_SUCCESS = "FB_AUTH_LOGIN_SUCCESS"
export const FB_AUTH_LOGIN_ERROR = "FB_AUTH_LOGIN_ERROR"

export const FB_AUTH_API_REQUEST = "FB_AUTH_API_REQUEST"
export const FB_AUTH_API_SUCCESS = "FB_AUTH_API_SUCCESS"
export const FB_AUTH_API_ERROR = "FB_AUTH_API_ERROR"

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_REQUEST_SUCCESS = "USER_UPDATE_REQUEST_SUCCESS"
export const USER_UPDATE_REQUEST_ERROR = "USER_UPDATE_REQUEST_ERROR"
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST"
export const USER_RESET_REQUEST = "USER_RESET_REQUEST"

export const EMAIL_AUTH_PASSWORD_RESET_REQUEST =
  "EMAIL_AUTH_PASSWORD_RESET_REQUEST"
export const EMAIL_AUTH_PASSWORD_RESET_SUCCESS =
  "EMAIL_AUTH_PASSWORD_RESET_SUCCESS"
export const EMAIL_AUTH_PASSWORD_RESET_ERROR = "EMAIL_AUTH_PASSWORD_RESET_ERROR"

// does not follow the patern REQUERST/SUCCESS/ERROR since it is syncronus action
export const EMAIL_AUTH_LOGOUT = "EMAIL_AUTH/LOGOUT"

export const EMAIL_AUTH_PASSWORD_RECOVER_REQUEST =
  "EMAIL_AUTH/PASSORD_RECOVER/REQUEST"
export const EMAIL_AUTH_PASSWORD_RECOVER_SUCCESS =
  "EMAIL_AUTH/PASSORD_RECOVER/SUCCESS"
export const EMAIL_AUTH_PASSWORD_RECOVER_ERROR =
  "EMAIL_AUTH/PASSORD_RECOVER/ERROR"
