import { all, takeEvery } from "redux-saga/effects"

// import EmailAuthSaga from '../features/Register/redux/sagas';
import MenuCategorySaga from "../features/Menu/redux/sagas"
import DriverSaga from "../features/Driver/redux/sagas"
import OrdersSaga from "../features/Orders/redux/sagas"
import Register from "../features/Register/redux/sagas"
import Login from "../features/Login/redux/sagas"
import setAudioData from "../features/MyFiles/redux/sagas"
import writeJournal from "../features/WriteJournal/redux/saga"

//@BlueprintReduxSagaImportInsertion

function* helloSaga() {
  console.log("Hello from saga!")
}

export function* mainSaga() {
  yield all([
    takeEvery("TEST/ALO", helloSaga),
    // other sagas go here

    // EmailAuthSaga,
    MenuCategorySaga,
    DriverSaga,
    OrdersSaga,
    Login,
    Register,
    setAudioData,
    writeJournal
    //@BlueprintReduxSagaMainInsertion
  ])
}
