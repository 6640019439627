import { all, takeLatest, put, call,select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
    ORDER_GET_REQUEST_SUCCESS,
    ORDER_GET_REQUEST,
    ORDER_ASSIGN_REQUEST,
    ORDER_ASSIGN_REQUEST_SUCCESS,
    ORDER_ASSIGN_REQUEST_ERROR,
    ORDER_STATUS_CHANGE_REQUEST,
    ORDER_STATUS_CHANGE_REQUEST_ERROR
} from './constants';
import { request } from '../../../utils/http';


function getRequest({place}) {
    request.defaults.timeout = 50000;
    const res = request.get(`/api/v1/bill/?place=${place}`);
    return res;
}

function assignDriverRequest({ data }) {
    var bodyFormData = new FormData();
    if(data.expected_delivery_time){
        bodyFormData.append("expected_delivery_time", data.expected_delivery_time);
    }
    bodyFormData.append("assign_driver_profile", data.assign_driver_profile);
    bodyFormData.append("status", 'chef_assigned');
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
    return request.patch('/api/v1/bill/' + data.id + '/', bodyFormData);
}

function statusOrderRequest({ data }) {
    var bodyFormData = new FormData();
    bodyFormData.append("status", data.status);
    request.defaults.headers.common["Content-Type"] = "multipart/form-data";
    request.defaults.timeout = 50000;
    return request.patch('/api/v1/bill/' + data.id + '/', bodyFormData);
}


function* getOrders(action) {
    try {
        const user = yield select(state => state.EmailAuth.user);
        console.log("getOrders",user.place.id)
        const { status, data } = yield call(getRequest, {place: user.place.id});
        if (status === 200) {
            yield put({ type: ORDER_GET_REQUEST_SUCCESS, data: data.filter(x=> x.place === user.place.id) });
        }
    } catch (error) {
        console.log("getDrivers", error);
    }
}

function* assignDriver(action) {
    try {
        console.log("assignDriver:: ", action)
        const { status, data } = yield call(assignDriverRequest, { data: action.data });
        if (status === 200) {
            toast.success("Chef assigned successfully..");
            yield put({ type: ORDER_ASSIGN_REQUEST_SUCCESS, order: data });
        } else {
            yield put({ type: ORDER_ASSIGN_REQUEST_ERROR, error: "Error in assigning chef." });
            toast.error("Fields are missing..")
        }
    } catch (error) {
        console.log(error);
        toast.error("Error in assigning chef.")
        yield put({ type: ORDER_ASSIGN_REQUEST_ERROR, error: "Error in assigning chef." });
    }
}

function* statusOrder(action) {
    try {
        console.log("statusOrder:: ", action)
        const { status, data } = yield call(statusOrderRequest, { data: action.data });
        if (status === 200) {
            if(action.data.status === 'ongoing'){
              toast.success("Order confirmed successfully..");
            }else if(action.data.status === 'cancelled'){
              toast.success("Order cancelled successfully..");
            }else if(action.data.status === 'delivered'){
              toast.success("Order delivered successfully..");
            }
            yield put({ type: ORDER_ASSIGN_REQUEST_SUCCESS, order: data });
        } else {
            yield put({ type: ORDER_STATUS_CHANGE_REQUEST_ERROR, error: "Error in cancelling order." });
            toast.error("Error in status changed order.")
        }
    } catch (error) {
        console.log(error);
        toast.error("Error in status changed order.")
        yield put({ type: ORDER_STATUS_CHANGE_REQUEST_ERROR, error: "Error in cancelling order." });
    }
}

export default all([
    takeLatest(ORDER_GET_REQUEST, getOrders),
    takeLatest(ORDER_ASSIGN_REQUEST, assignDriver),
    takeLatest(ORDER_STATUS_CHANGE_REQUEST, statusOrder)
]);
