import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

// import EmailAuthReducer from '../features/Register/redux/reducers';
import MenuCategoryReducer from "../features/Menu/redux/reducers"
import DriverReducer from "../features/Driver/redux/reducers"
import OrderReducer from "../features/Orders/redux/reducers"
import Login from "../features/Login/redux/reducers"
import Register from "../features/Register/redux/reducers"
import setAudioData from "../features/MyFiles/redux/reducers"
import writeJournal from "../features/WriteJournal/redux/reducer"
/**
 * You can import more reducers here
 */

const logInPersistConfig = {
  key: "login",
  storage,
  timeout: null
}

//@BlueprintReduxImportInsertion

export const combinedReducers = combineReducers({
  blank: (state, action) => {
    if (state == null) state = []
    return state
  },

  Login: persistReducer(logInPersistConfig, Login),
  Register,
  // EmailAuth: EmailAuthReducer,
  MenuCategory: MenuCategoryReducer,
  Driver: DriverReducer,
  Order: OrderReducer,
  setAudioData: setAudioData,
  writeJournal: writeJournal

  //@BlueprintReduxCombineInsertion
})
