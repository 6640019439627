import * as actions from "./constants";

const initialState = {
    audioData: []
};

const setAudioData = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_AUDIO_DATA:
            return {
                ...state,
                audioData: action.AudioData
            };

        default:
            return state;
    }
};

export default setAudioData;