import * as actions from "./constants";

const initialState = {
  categories: [],
  item_categories: [],
  item_varients: [],
  selectedCategory: null,
  cart: [],
  specific_item: null,
  reviewResponse: null,
  reviewError: null,
  addProductResponse: null,
  product_update: null,
  category_update: null
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CATEGORY_UPDATE:
      return {
        ...state,
        categories: action.data
      };
    case actions.CATEGORY_ITEM_VARIANT_UPDATE:
      return {
        ...state,
        item_varients: action.data
      };
    case actions.CATEGORY_ITEM_VARIANT_ADD:
      state.item_varients.push(action.data);
      return {
        ...state,
        item_varients: [...state.item_varients]
      };
    case actions.CATEGORY_ITEM_UPDATE:
      return {
        ...state,
        item_categories: action.data
      };
    case actions.CATEGORY_ITEM_NAVIGATE:
      return {
        ...state,
        selectedCategory: action.data
      };

    case actions.SET_SPECIFIC_ITEM:
      return {
        ...state,
        specific_item: action.specific_item
      };
    case actions.REVIEW_ITEM_COMPLETE:
      return {
        ...state,
        reviewResponse: action.reviewResponse
      };
    case actions.REVIEW_ITEM_ERROR:
      return {
        ...state,
        reviewError: action.error
      };
    case actions.ADD_CATEGORY_UPDATE:
      state
        .categories
        .push(action.addCategoryResponse);
      return {
        ...state,
        categories: state
          .categories
          .slice()
      };
    case actions.ADD_PRODUCT_UPDATE:
      state
        .item_categories
        .push(action.addProductResponse);
      return {
        ...state,
        item_categories: state
          .item_categories
          .slice()
      };

    case actions.UPDATE_VARIANT_ADD:
      const variantItems = action.addProductResponse;
      for (let i = 0; i < variantItems.length; i++) {
        const element = variantItems[i];
        const index = state
          .item_varients
          .findIndex((obj => obj.id === element.id));
        if (index === -1) {
          state
            .item_varients
            .push(element);
        } else {
          state.item_varients[index] = element;
        }
      }
      return {
        ...state,
        item_varients: state
          .item_varients
          .slice()
      };
    case actions.UPDATE_VARIANT_UPDATE:
      const index = state
        .item_varients
        .findIndex((obj => obj.id === action.addProductResponse.id));
      state.item_varients[index] = action.addProductResponse;
      return {
        ...state,
        item_varients: state
          .item_varients
          .slice()
      };

    case actions.UPDATE_CATEGORY_UPDATE:
      const CIndex = state
        .categories
        .findIndex((obj => obj.id === action.category.id));
      state.categories[CIndex] = action.category;
      return {
        ...state,
        categories: state
          .categories
          .slice(),
        category_update: action.category
      };
    case actions.DELETE_CATEGORY_REQUEST_SUCCESS:
      const dIndex = state
        .categories
        .findIndex((obj => obj.id === action.id));
      state
        .categories
        .splice(dIndex, 1);
      return {
        ...state,
        categories: state
          .categories
          .slice()
      };

    case actions.DELETE_PRODUCT_REQUEST_SUCCESS:
      const pdIndex = state
        .item_categories
        .findIndex((obj => obj.id === action.id));
      state
        .item_categories
        .splice(pdIndex, 1);
      return {
        ...state,
        item_categories: state
          .item_categories
          .slice()
      };
    case actions.UPDATE_PRODUCT_UPDATE:
      const objIndex = state
        .item_categories
        .findIndex((obj => obj.id === action.addProductResponse.id));
      state.item_categories[objIndex] = action.addProductResponse;
      return {
        ...state,
        item_categories: state
          .item_categories
          .slice(),
        product_update: action.addProductResponse
      };
    case actions.UPDATE_CART:
      console.log(action.cart);
      return {
        ...state,
        cart: action
          .cart
          .slice()
      };
    case actions.CLEAR_CART:
      return {
        ...state,
        cart: []
      };

    case actions.STATE_DEFAULT:
      console.log("STATE_DEFAULT");
      return {
        ...state,
        reviewResponse: null,
        reviewError: null,
        update_product: null,
        category_update: null
      };
    case "EMAIL_AUTH/LOGOUT":
      console.log("STATE_DEFAULT");
      return initialState;
    default:
      return state;
  }
};

export default CategoryReducer;