import types from "./types"

export const addjournals = (data) => ({
  type: types.addjournals,
  data,
})

export const getEntries = () => ({
  type: types.getEntries
})


export const reset = () => ({
  type: types.reset
})

export const getEntrieSuccess = data => ({
  type: types.getEntrieSuccess,
  data
})

export const addjournalsError = data => ({
  type: types.addjournalsError,
  data
})

export const addjournalSuccess = data => ({
  type: types.addjournalSuccess,
  data
})

